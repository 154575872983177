






































import { Vue, Component } from 'vue-property-decorator'
import { getError } from '@/utils/helpers'
import AuthService from '@/services/AuthService'

@Component
export default class ResetPassword extends Vue {
    email = '';
    password = '';
    passwordConfirm = '';
    error = '';
    message = '';

    resetPassword () {
        this.error = ''
        this.message = ''
        const payload = {
            email: this.email,
            password: this.password,
      password_confirmation: this.passwordConfirm, // eslint-disable-line
            token: this.$route.query.token
        }
        AuthService.resetPassword(payload)
            .then((response) => {
                this.message = response.data.message
                this.$router.push('/dashboard').catch(() => { console.log('') })
            })
            .catch((error) => (this.error = getError(error)))
    }
}
